<script setup lang="ts">
import { reset } from '@formkit/core'
import type { Api } from '@rialtic/api'
import { useApi } from '~/stores/api'

const props = defineProps<{ policyId: string }>()
const emit = defineEmits(['cancel', 'error', 'success'])

const api = useApi()
const { $datadog } = useNuxtApp()

const state = reactive({
  loading: false,
})
// controls open/close state of source types
const isEditorOpen = reactive({
  sourceType1: false,
  sourceType2: false,
})

const referenceForm = reactive({
  source: '',
  source_type_level_1: '',
  source_type_level_2: '',
  regions: [],
})

const createReferenceDocument = async (body: any) => {
  try {
    const result = await api.fetch<any>('/reference-document?include=true', {
      method: 'POST',
      body: { ...body, policies: [{ policy_id: props.policyId }] },
    })

    emit('success', result)
  } catch (error) {
    $datadog.addError(error, {
      policy_id: props.policyId,
    })
  }
}

const onSubmit = async (data: Api.ReferenceDoc) => {
  emit('error', '')
  state.loading = true
  try {
    const res = await createReferenceDocument(data)
    reset('referenceDocForm')
  } catch (error) {
    console.error(error)

    emit('error', error.message)
  } finally {
    state.loading = false
  }
}

const showErrorMessages = (payload, node) => {
  console.log('error messages', payload, node)
}
</script>

<template>
  <div class="p-4">
    <FormKit
      id="referenceDocForm"
      messages-class="mt-4 text-center text-error text-sm"
      :actions="false"
      :config="{ validationVisibility: 'submit' }"
      autocomplete="off"
      type="form"
      @submit="onSubmit"
      @submit-invalid="showErrorMessages"
    >
      <div class="p-6">
        <section>
          <h2 class="border-b-1 pb-2 text-xl">Source Details</h2>
          <section class="flex py-5">
            <h3 class="w-45 mr-5 text-sm">
              Source
              <span class="text-red-700">*</span>
            </h3>
            <FormKit
              v-model="referenceForm.source"
              name="source"
              inner-class="w-full overflow-hidden border border-neutral-200 rounded-md focus-within:border-primary"
              input-class="w-full border-none p-3 body-2 bg-transparent placeholder-neutral-500 text-neutral-900 outline-none"
              outer-class="flex-1"
              placeholder="type here"
              type="text"
              validation="required"
            />
          </section>
          <section class="flex py-5">
            <h3 class="w-45 mr-5 text-sm">
              Source type level 1
              <span class="text-red-700">*</span>
            </h3>
            <FormFieldSourceTypeLevelOne
              v-model="isEditorOpen.sourceType1"
              :is-title-disabled="true"
              :value="referenceForm.source_type_level_1"
              @save="
                (sourceTypeId) => {
                  referenceForm.source_type_level_1 = sourceTypeId
                  referenceForm.source_type_level_2 = null
                  referenceForm.regions = []
                }
              "
            />
            <FormKit
              v-model="referenceForm.source_type_level_1"
              name="source_type_level_1"
              type="hidden"
              validation="required"
              validation-label="Source type level 1"
            />
            <!-- TODO: needs error messaging -->
          </section>
          <section class="flex py-5">
            <h3 class="w-45 mr-5 text-sm">Source type level 2</h3>
            <FormFieldSourceTypeLevelTwo
              v-model="isEditorOpen.sourceType2"
              :is-title-disabled="true"
              :level-one-id="referenceForm.source_type_level_1"
              :value="referenceForm.source_type_level_2"
              :value-regions="referenceForm.regions"
              @save="
                (sourceTypeId) =>
                  (referenceForm.source_type_level_2 = sourceTypeId)
              "
              @save-region="
                (regions) =>
                  (referenceForm.regions = regions.map((region) => ({
                    region_id: region.id,
                  })))
              "
            />
            <FormKit
              v-model="referenceForm.source_type_level_2"
              name="source_type_level_2"
              type="hidden"
              validation-label="Source type level 2"
            />
            <!-- TODO: needs error messaging -->
            <FormKit
              v-model="referenceForm.regions"
              name="regions"
              type="hidden"
              validation-label="Source type level 2 regions"
            />
            <!-- TODO: needs error messaging -->
          </section>
        </section>
      </div>
      <footer class="flex justify-end space-x-4">
        <UiButton size="sm" variant="text" @click="$emit('cancel')">
          Cancel
        </UiButton>
        <UiButton size="sm" type="submit">Save</UiButton>
      </footer>
    </FormKit>
  </div>
</template>
