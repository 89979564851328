<script setup lang="ts">
import type { Api } from '@rialtic/api'
import { isAfter } from 'date-fns'
import { useEditor } from '~/stores/editor'

const props = defineProps<{ loading: boolean; policy: Api.Policy }>()
const emit = defineEmits(['details-open', 'refresh', 'success', 'update'])

const editor = useEditor()

const refDocManagerOpen = ref(false)
const viewPolicies = ref<string | null>(null)
const editRefDoc = ref<string | null>(null)

const { data: sourceTypeLevelOne } = useLazyAsyncData<Api.SourceTypeLevel1[]>(
  '/source-type-level-one',
  () => {
    return editor.$apiFetch('/source-type-level-one')
  },
)
const { data: sourceTypeLevelTwo } = useLazyAsyncData<Api.SourceTypeLevel2[]>(
  '/source-type-level-two',
  () => {
    return editor.$apiFetch('/source-type-level-two')
  },
)

const referenceDocuments = computed(() => {
  return (
    props.policy.reference_document_policy?.map(
      ({ reference_document: doc, reference_document_id: id }) => {
        return {
          id,
          doc: {
            source_type_level_1: sourceTypeLevelOne.value?.find(
              (item) => item.id === doc.source_type_level_1_id,
            ),
            source_type_level_2: sourceTypeLevelTwo.value?.find(
              (item) => item.id === doc.source_type_level_2_id,
            ),
            ...doc,
          },
        }
      },
    ) || []
  )
})

const assignRefDocs = async (docIds: string[]) => {
  await emit('update', {
    reference_documents: docIds.map((reference_document_id) => ({
      reference_document_id,
    })),
  })
  refDocManagerOpen.value = false
}

const onOpenRefDocManager = () => {
  emit('details-open')
  refDocManagerOpen.value = true
}

const onRefDocUpdateSuccess = () => {
  editRefDoc.value = null
  emit('refresh')
  emit('success')
}

const isDocRetired = (retiredDate: string): boolean => {
  const date = new Date()
  const today = new Date(
    date.getTime() - date.getTimezoneOffset() * 60000,
  ).toJSON()
  const normalizedToday = stripTimeFromDate(today).replaceAll('-', '/')
  const normalizedRetiredDate = stripTimeFromDate(retiredDate).replaceAll(
    '-',
    '/',
  )

  return (
    normalizedToday === normalizedRetiredDate ||
    isAfter(new Date(normalizedToday), new Date(normalizedRetiredDate))
  )
}
</script>

<template>
  <div class="p-4 lg:px-4">
    <header class="flex py-4">
      <UiButton size="xs" @click="onOpenRefDocManager">
        Manage Reference Documents
      </UiButton>
    </header>
    <ul class="flex flex-col divide-y-2 overflow-hidden rounded-md shadow">
      <li v-for="{ doc, id } in referenceDocuments" :key="id">
        <UiListItemExpand>
          <div class="flex h-full w-full items-center justify-between">
            <div class="flex items-center space-x-2">
              <p class="h3 text-left text-neutral-800">
                {{ doc.source }}
              </p>
            </div>
            <div>
              <UiButton size="sm" @click.stop="editRefDoc = doc.id">
                Edit
              </UiButton>
            </div>
          </div>
          <div class="flex w-full items-center justify-between pt-2">
            <p class="body-2 font-bold text-neutral-900">
              {{ doc.source_type_level_1?.name }}
            </p>
          </div>
          <div class="flex items-center justify-between space-x-2 pt-2">
            <UiLabel
              class="border border-neutral-700"
              @click="viewPolicies = doc.id"
            >
              Connected Policies: {{ doc?.reference_document_policy?.length }}
            </UiLabel>
            <UiLabel v-if="doc.is_primary" class="bg-primary-300 text-white">
              Primary
            </UiLabel>
            <UiLabel v-if="doc.is_hidden">Hidden</UiLabel>
            <UiLabel
              v-if="doc.retired_date && isDocRetired(doc.retired_date)"
              class="bg-error-100 text-error-700"
            >
              Retired
            </UiLabel>
            <UiLabel v-else-if="doc.retired_date" class="bg-exclusion">
              Retires on {{ stripTimeFromDate(doc.retired_date) }}
            </UiLabel>
          </div>
          <div
            v-if="!doc.source_type_level_1_id"
            class="text-warning-dark body-1 flex items-center pt-2"
          >
            <span class="i-ph-warning mr-1 inline-block" />
            Missing source type level 1
          </div>

          <!-- <AppDetailsDrawer v-if="viewPolicies === doc.id" class="text-left">
            <header>
              <h1>{{ doc.source }} Policies</h1>
            </header>
            <div>
              <pre>{{ doc.reference_document_policy }}</pre>
            </div>
          </AppDetailsDrawer> -->

          <Teleport to="body">
            <AppDetailsDrawer v-if="editRefDoc === doc.id" class="text-left">
              <header class="p-4 px-6">
                <h1>Edit {{ doc.source }}</h1>
              </header>
              <div>
                <FormUpdateRefDoc
                  :doc="doc"
                  :policy-id="policy.id"
                  @cancel="editRefDoc = null"
                  @success="onRefDocUpdateSuccess"
                />
              </div>
            </AppDetailsDrawer>
          </Teleport>

          <template #content="{ isOpen }">
            <div class="border-t-2 p-4">
              <ul class="flex-col space-y-2 pb-4">
                <li>
                  <p class="subtitle-1 text-neutral-800">Connected Policies</p>
                  <div class="grid-cols-auto grid gap-2 pt-1">
                    <NuxtLink
                      v-for="{ policy_id } in doc.reference_document_policy"
                      :to="`/policy-editor/${policy_id}/reference-document`"
                      class="body-2 text-secondary underline"
                    >
                      {{ policy_id }}
                    </NuxtLink>
                  </div>
                </li>
              </ul>

              <ReferenceDocDetails :doc="doc" />

              <LazyReferenceDocVersionTimeline
                v-if="isOpen"
                :doc="doc"
                :policy-id="policy.id"
                @refresh="$emit('refresh')"
                @success="$emit('success')"
              />
            </div>
          </template>
        </UiListItemExpand>
      </li>
    </ul>
    <ApiRefDocSelector
      v-model="refDocManagerOpen"
      :policy-id="policy.id"
      :policy-ref-docs="policy.reference_document_policy"
      @save="assignRefDocs"
    />
    <NuxtPage />
  </div>
</template>
