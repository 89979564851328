<script setup lang="ts">
import type { PaAPI } from '@rialtic/types'
type ActiveRefDocs = Record<string, true>
type ReferenceDoc = PaAPI.Get['/reference-document/{id}']
type RefDocName = Pick<ReferenceDoc, 'id' | 'source'> & { policy_ids: string[] }

const props = defineProps<{
  policyId: string
  modelValue: boolean
  policyRefDocs?: PaAPI.Get['/policy/{id}']['reference_document_policy']
}>()

const emit = defineEmits(['save', 'update:modelValue'])

const createOpen = ref(false)
const search = ref('')
const showAssigned = ref(false)
const formRefDocs = ref({} as ActiveRefDocs)

const activeRefDocs = computed<ActiveRefDocs>(() => {
  return (
    props.policyRefDocs?.reduce((state, item) => {
      state[item.reference_document_id] = true
      return state
    }, {} as ActiveRefDocs) || {}
  )
})

const open = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value),
})

const config = useRuntimeConfig().public
const apiClient = useApiClient({ baseURL: <string>config.WORKERS_API_BASE_URL })
const {
  data: refDocNames,
  pending,
  refresh,
} = await useLazyAsyncData<RefDocName[]>(
  '/policy-manager/ref-doc-names',
  async () => {
    const results = await apiClient.fetch<RefDocName[]>(
      '/policy-manager/ref-doc-names',
    )
    return results ?? []
  },
)

const assignedDocsAndChanges = computed(() => {
  if (!refDocNames.value?.length) {
    return []
  }
  if (!showAssigned.value) {
    return refDocNames.value
  }
  return refDocNames.value.filter(
    (item) =>
      !!activeRefDocs.value[item.id] ||
      !!formRefDocs.value[item.id] !== !!activeRefDocs.value[item.id],
  )
})

const refDocs = useSimpleSearch(assignedDocsAndChanges, search, 'source')

watch(
  () => props.modelValue,
  (isOpen, prevIsOpen) => {
    if (isOpen && !prevIsOpen) {
      formRefDocs.value = activeRefDocs.value
    }
  },
)

const addDoc = (item: ReferenceDoc) => {
  formRefDocs.value = { ...formRefDocs.value, [item.id]: true }
}

const removeDoc = (itemKey: ReferenceDoc['id']) => {
  const filters = { ...formRefDocs.value }
  delete filters[itemKey]
  formRefDocs.value = filters
}

const updateDoc = (item: ReferenceDoc) => {
  if (!item) return

  if (formRefDocs.value[item.id]) {
    removeDoc(item.id)
  } else {
    addDoc(item)
  }
}

const onDocCreated = async (doc: ReferenceDoc) => {
  await refresh()
  updateDoc(doc)
  createOpen.value = false
}

const { list, containerProps, wrapperProps } = useVirtualList(refDocs, {
  // Keep `itemHeight` in sync with the item's row.
  itemHeight: 40,
})
</script>

<template>
  <AppDetailsDrawer v-if="open">
    <div class="flex h-full flex-col">
      <header class="border-b border-neutral-200 px-4 py-2">
        <div class="flex w-full justify-between font-semibold">
          <div class="flex items-center space-x-2">
            <h1>Reference Documents</h1>
            <UiSearch
              v-model="search"
              :loading="pending"
              placeholder="Search"
            />
            <UiButton size="xs" @click="createOpen = true">Create new</UiButton>
            <UiToggle v-model="showAssigned" label="Show assigned">
              Show assigned
            </UiToggle>
          </div>

          <button class="rounded-full p-1" @click="open = false">
            <div class="i-ph-x-bold" />
          </button>
        </div>
      </header>

      <div v-bind="containerProps" class="flex-1 overflow-y-auto">
        <ul v-bind="wrapperProps" class="flex flex-col space-y-1 p-1">
          <li v-for="{ data: item } in list" :key="item.id">
            <UiListItemCheckbox
              :checked="!!formRefDocs[item.id]"
              @click="updateDoc(item)"
            >
              <div class="flex h-full items-start justify-start space-x-1">
                <p>{{ item.source }}</p>
                <div
                  v-if="!!formRefDocs[item.id] !== !!activeRefDocs[item.id]"
                  class="i-ph-circle-fill text-primary-300 mt-1 h-2 min-h-2 w-2 min-w-2"
                />
              </div>

              <template #append>
                <div class="flex flex-1 flex-wrap justify-end space-x-2 pl-2">
                  <UiMenu v-for="policy_id in item.policy_ids">
                    <template #activator="{ toggle }">
                      <UiLabel
                        class="cursor-context-menu"
                        :class="{
                          'bg-primary-100': policy_id === $route.params?.id,
                        }"
                        @contextmenu.prevent="toggle()"
                      >
                        {{ policy_id }}
                      </UiLabel>
                    </template>
                    <div class="p-2">
                      <NuxtLink
                        :to="`/policy-editor/${policy_id}`"
                        class="text-secondary-300 flex items-center space-x-2"
                      >
                        <div>Go to {{ policy_id }}</div>
                        <div class="i-ph-arrow-right-bold h-4 w-4" />
                      </NuxtLink>
                    </div>
                  </UiMenu>
                </div>
              </template>
            </UiListItemCheckbox>
          </li>
        </ul>
      </div>
      <UiDialog v-if="createOpen" :model-value="true" max-width="520px">
        <FormCreateRefDoc
          :policy-id="policyId"
          @cancel="createOpen = false"
          @success="onDocCreated"
        />
      </UiDialog>
      <footer
        class="flex items-center justify-start space-x-2 border-t border-neutral-200 px-4 py-2"
      >
        <UiButton class="btn" @click="emit('save', Object.keys(formRefDocs))">
          Save
        </UiButton>
        <UiButton class="px-4 py-2" variant="text" @click="open = false">
          Cancel
        </UiButton>
        <div class="flex-1" />
      </footer>
    </div>
  </AppDetailsDrawer>
</template>
