<script setup lang="ts">
import type { PaAPI } from '@rialtic/types'
import { subDays } from 'date-fns'
import { useEditor } from '~/stores/editor'

type ReferenceDoc = PaAPI.Get['/reference-document/{id}']
const props = defineProps<{ policyId: string; doc: ReferenceDoc }>()
const emit = defineEmits(['cancel', 'error', 'success'])

const editor = useEditor()
const { $datadog } = useNuxtApp()

const state = reactive({
  loading: false,
})
const isRetired = ref(!!props.doc.retired_date)

// controls open/close state of source types
const isEditorOpen = reactive({
  sourceType1: false,
  sourceType2: false,
})

const referenceForm = reactive({
  is_hidden: !!props.doc?.is_hidden,
  is_primary: !!props.doc?.is_primary,
  retired_date: props.doc?.retired_date
    ? stripTimeFromDate(props.doc?.retired_date)
    : null,
  source: props.doc?.source || '',
  source_type_level_1: props.doc?.source_type_level_1_id || '',
  source_type_level_2: props.doc?.source_type_level_2_id || '',
  regions: props.doc?.reference_document_region || [],
})

interface SubmitData extends ReferenceDoc {
  regions: Partial<ReferenceDoc['reference_document_region'][number]>[]
}
const updateReferenceDocument = async (body: SubmitData) => {
  // shim fix for now
  if (body.regions.length) {
    const regionsClone = useLodashCloneDeep(body.regions)
    body.regions = regionsClone.map((region) => ({
      region_id: region.region_id,
    }))
  }

  // tied to toggle and must return null at the very least
  body.retired_date = body.retired_date || null

  try {
    const result = await editor.$apiFetch<ReferenceDoc>(
      `/reference-document/${props.doc.id}`,
      {
        method: 'PUT',
        body,
      },
    )

    emit('success', result)
  } catch (error) {
    $datadog.addError(error, {
      policy_id: props.policyId,
      reference_doc_id: props.doc.id,
    })
  }
}

const onSubmit = async (data: SubmitData) => {
  emit('error', '')
  state.loading = true
  try {
    await updateReferenceDocument(data)
  } catch (error) {
    console.error(error)

    emit('error', error.message)
  } finally {
    state.loading = false
  }
}

const showErrorMessages = (payload, node) => {
  console.log('error messages', payload, node)
}

const latestStartDate = computed(() => {
  // we fall back on today if there are no versions
  let date = stripTimeFromDate(`${new Date().toISOString()}`)
  if (props.doc.reference_document_version.length) {
    // we know an end date of null is the last entry
    const docVersion = props.doc.reference_document_version.find(
      (version) => version.effective_date_end === null,
    )
    if (!docVersion?.effective_date_start) {
      return date
    }

    try {
      const dateMinusOne = subDays(new Date(docVersion.effective_date_start), 1)
      date = stripTimeFromDate(dateMinusOne.toISOString())
    } catch (error) {}
  }

  return date
})
</script>

<template>
  <div class="p-4">
    <header>
      <UiLabel class="border border-neutral-700">
        Connected Policies: {{ doc?.reference_document_policy?.length }}
      </UiLabel>
    </header>

    <FormKit
      messages-class="mt-4 text-center text-error text-sm"
      :actions="false"
      :config="{ validationVisibility: 'submit' }"
      autocomplete="off"
      type="form"
      @submit="onSubmit"
      @submit-invalid="showErrorMessages"
    >
      <section class="pt-4">
        <div class="flex space-x-4">
          <UiToggle v-model="referenceForm.is_primary" label="Is primary" />
          <UiToggle v-model="referenceForm.is_hidden" label="Is hidden" />
          <UiToggle v-model="isRetired" label="Is retired" />

          <FormKit
            v-model="referenceForm.is_primary"
            name="is_primary"
            type="hidden"
          />
          <FormKit
            v-model="referenceForm.is_hidden"
            name="is_hidden"
            type="hidden"
          />
        </div>
        <UiTransitionSlideDown>
          <section v-if="isRetired" class="flex py-5">
            <h3 class="w-45 mr-5 text-sm">Retired on</h3>
            <FormKit
              v-model="referenceForm.retired_date"
              name="retired_date"
              input-class="px-4 py-2 body-2 border border-neutral-200 rounded-lg"
              :validation="[['date_after', latestStartDate]]"
              type="date"
            />
          </section>
        </UiTransitionSlideDown>
        <section class="flex py-5">
          <h3 class="w-45 mr-5 text-sm">
            Source
            <span class="text-red-700">*</span>
          </h3>
          <FormKit
            v-model="referenceForm.source"
            name="source"
            inner-class="w-full overflow-hidden border border-neutral-200 rounded-md focus-within:border-primary"
            input-class="w-full border-none p-3 body-2 bg-transparent placeholder-neutral-500 text-neutral-900 outline-none"
            outer-class="flex-1"
            placeholder="type here"
            type="text"
            validation="required"
          />
        </section>
        <section class="flex py-5">
          <h3 class="w-45 mr-5 text-sm">
            Source type level 1
            <span class="text-red-700">*</span>
          </h3>
          <FormFieldSourceTypeLevelOne
            v-model="isEditorOpen.sourceType1"
            :is-title-disabled="true"
            :value="referenceForm.source_type_level_1"
            @save="
              (sourceTypeId) => {
                referenceForm.source_type_level_1 = sourceTypeId
                referenceForm.source_type_level_2 = null
                referenceForm.regions = []
              }
            "
          />
          <FormKit
            v-model="referenceForm.source_type_level_1"
            name="source_type_level_1"
            type="hidden"
            validation="required"
            validation-label="Source type level 1"
          />
          <!-- TODO: needs error messaging -->
        </section>
        <section class="flex py-5">
          <h3 class="w-45 mr-5 text-sm">Source type level 2</h3>
          <FormFieldSourceTypeLevelTwo
            v-model="isEditorOpen.sourceType2"
            :is-title-disabled="true"
            :level-one-id="referenceForm.source_type_level_1"
            :value="referenceForm.source_type_level_2"
            :value-regions="referenceForm.regions"
            @save="
              (sourceTypeId) =>
                (referenceForm.source_type_level_2 = sourceTypeId)
            "
            @save-region="
              (regions) =>
                (referenceForm.regions = regions.map((region) => ({
                  region_id: region.id,
                })))
            "
          />
          <FormKit
            v-model="referenceForm.source_type_level_2"
            name="source_type_level_2"
            type="hidden"
            validation-label="Source type level 2"
          />
          <!-- TODO: needs error messaging -->
          <FormKit
            v-model="referenceForm.regions"
            name="regions"
            type="hidden"
            validation-label="Source type level 2 regions"
          />
          <!-- TODO: needs error messaging -->
        </section>
      </section>
      <footer class="flex justify-end space-x-4">
        <UiButton size="sm" variant="text" @click="$emit('cancel')">
          Cancel
        </UiButton>
        <UiButton :loading="state.loading" size="sm" type="submit">
          Save
        </UiButton>
      </footer>
    </FormKit>
  </div>
</template>

<style>
/* TODO: Need DRIER solution */
.formkit-message {
  @apply text-error-dark text-sm;
}
</style>
